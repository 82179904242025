<template>
  <Modal size="4xl">
    <div class="absolute ml-3">
      <a-icon type="setting" :style="{ fontSize: '24px' }"></a-icon>
    </div>
    <h1 class="text-3xl mb-2 text-blue-700 font-bold">
      Configuración de alertas
    </h1>
    <span>
      <b>Editar configuración para alertas sobre</b> <br />
      {{ form.help_text_alert }}
    </span>
    <div class="grid gap-7 grid-cols-2 my-5 px-5 pb-6 rounded-xl border mx-2">
      <BaseInput
        type="text"
        label="Tipo de alertas"
        :disabled="true"
        v-model="form.type_alert_name"
      />

      <BaseInput
        type="text"
        label="Nombre de la alerta"
        :disabled="true"
        v-model="form.name"
      />
      <BaseInput
        type="text"
        label="Agrupador"
        :disabled="true"
        v-model="form.visible_name"
      />
      <BaseInput
        :type=" form.alert_type == 5 || form.alert_type == 3 || form.alert_type == 6 ? 'text': 'number'"
        :label="form.limit_rows_name"
        placeholder="Ej: 10"
        v-model="form.limit_rows"
        :disabled="
          form.alert_type == 5 || form.alert_type == 3 || form.alert_type == 6
        "
        :tooltip="form.help_text_alert"
      />
      <BaseInput
        v-if="form.alert_type != 2 && form.alert_type != 3"
        :type="form.alert_type == 5 || form.alert_type == 6 ? 'text':'number'"
        :label="form.threshold_row_name"
        placeholder="Ej: 2.3"
        v-model="form.threshold_row"
        :disabled="form.alert_type == 5 || form.alert_type == 6"
      />
      <Slider
        v-else
        isPercentage
        :defaultValue="form.threshold_row"
        @onChange="form.threshold_row = $event"
        :marks="{ 0: '0%', 25: '25%', 50: '50%', 75: '75%', 100: '100%' }"
        class="text-left text-xs md:text-xs text-gray-600 text-opacity-80 mt-2"
        :label="form.threshold_row_name"
      ></Slider>
      <BaseSelect
        :selectOptions="frequency"
        type="text"
        label="Frecuencia"
        placeholder="Ej: 8"
        v-model="form.frequency_alert"
        :disabled="form.alert_type == 1"
      />
    </div>
    <div class="w-11/12 flex mx-auto justify-center items-center gap-5 p-5">
      <a-button
        class="w-11/12 gap-2 sm:mt-auto mt-3 lg:11/12"
        type="danger"
        ghost
        shape="round"
        @click="close"
      >
        Cancelar
      </a-button>

      <a-button
        type="primary"
        shape="round"
        class="w-11/12 gap-2 sm:mt-auto mt-3 lg:w-11/12"
        @click="saveSettings"
      >
        Guardar
      </a-button>
    </div>
    <div class="absolute w-full h-full flex items-center justify-center">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      frequency: [
        { id: "1", name: "Semanal" },
        { id: "2", name: "Quincenal" },
        { id: "3", name: "Mensual" },
      ],
      form: {},
    };
  },
  created() {
    this.form = {
      ...this.alert,
      frequency_alert:
        this.alert.alert_type != 1 ? this.alert.frequency_alert + "" : 1 + "",
      limit_rows:
        this.alert.alert_type == 5 ||
        this.alert.alert_type == 3 ||
        this.alert.alert_type == 6
          ? "No aplica"
          : this.alert.limit_rows+'',
      threshold_row:
        this.alert.alert_type == 5 || this.alert.alert_type == 6
          ? "No aplica"
          : this.alert.threshold_row,
    };
  },
  methods: {
    async saveSettings() {
      let settings = {
        ...this.form
      };
      // Extra: Remove null values
      if (this.form.limit_rows == "No aplica") {
        delete settings['limit_rows'];
      }
      if (this.form.threshold_row == "No aplica") {
        delete settings['threshold_row'];
      }
      let { error, data } = await this.$api.setAlertSettings(
        settings,
        this.form.id
      );
      if (error) {
        this.showToast(
          "error",
          "Ocurrió un error al cambiar la configuración. Intente nuevamente."
        );
      }
      if (data) {
        this.$emit("close", { success: true });
        this.showToast("success", "Configuración actualizada con éxito.");
      }
    },

    close() {
      this.$emit("close", { success: false });
    },

    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  }
};
</script>
