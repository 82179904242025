<template>
  <div class="w-11/12 mx-auto mt-10">
    <TotalComplaints :total="count" title="Total" body="configuraciones" />
    <div v-if="!loading" class="mx-4">
      <div
        class="overflow-x-auto rounded-xl border border-gray-300 bg-white roles"
      >
        <a-table
          class="w-full"
          :columns="columns"
          rowKey="id"
          :data-source="data"
          :pagination="false"
        >
          <span
            slot="actions"
            slot-scope="text, record"
            class="text-sm font-medium text-right whitespace-nowrap flex"
          >
            <a-switch
              v-model="record.state"
              @change="onSwitchChange($event, record)"
            />
            <span
              class="ml-3 h-7 w-7 p-1 text-blue-600 dark:text-blue-500 shadow rounded"
              @click="editSettings($event, record)"
            >
              <img class="h-full w-full" src="@/assets/icons/pen.svg" alt="" />
            </span>
          </span>
        </a-table>
      </div>
      <div class="mt-4 flex justify-end" v-if="pageSize < count">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-size-changer
          size="large"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>

    <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
    <ModalSettings v-if="showModal" :alert="alert" @close="closeModal" />
  </div>
</template>
<script>
import capitalize from "@/utils/checkForms.js";
import ModalSettings from "@/components/Dashboard/Alerts/ModalSettings.vue";
import TotalComplaints from "../Complaints/TotalComplaints.vue";
const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: 200,
    sorter:(a,b)=>{if(a.name< b.name){return -1} if(a.name>b.name){return 1} return 0}
  },
  {
    title: "Tipo de Alerta",
    dataIndex: "alertType",
    key: "alertType",
    width: 190,
    sorter:(a,b)=>{if(a.alertType< b.alertType){return -1} if(a.alertType>b.alertType){return 1} return 0}
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    width: 10,
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: { ModalSettings, TotalComplaints },
  data() {
    return {
      data: [],
      columns,
      alert: {},
      loading: false,
      current: null,
      showModal: false,
      page: 1,
      searchTerm: null,
      pageSize: 10,
      count: 0,
      search: null,
      pageSizeOptions: ["20", "40", "60", "80", "100"],
    };
  },
  created() {
    if (this.$route.query.search) {
      this.searchTerm = this.$route.query.search;
      this.search = this.searchTerm;
    }
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 10;
    }
    this.start();
  },
  methods: {
    async start() {
      let query = `?page=${this.page}&page_size=${this.pageSize}`;
      this.loading = true;
      let { error, data } = await this.$api.getAlertSettings(query);
      this.loading = false;

      if (error) {
        this.data = [];
        this.showToast("error", "Error listando las configuraciones.");
      }
      if (data) {
        this.data = data.results.map((item) => {
          return {
            ...item,
            name: capitalize(item.name),
            alertType: item.type_alert_name,
            state: item.is_active,
          };
        });
        this.count = data.count;
      }
    },
    async onSwitchChange(checked, record) {
      this.showToast("info", "Procesando cambio de estado...");
      this.loading = true;
      let { error, data } = await this.$api.setStateSetting({
        configuration: record.id,
        state: checked ? 1 : 0,
      }); //Change state
      this.loading = false;

      if (error) {
        this.showToast("error", "Error cambiando estado del usuario.");
        console.error(error);
      }

      if (data) {
        this.showToast("success", "Cambio de estado éxitoso.");
        this.start();
      }
    },

    editSettings(ev, alert) {
      this.showModal = true;
      this.alert = alert;
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.start();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },

    closeModal(ev) {
      this.showModal = false;
      if (ev.success) this.start();
    },
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.start();
    },
  },
};
</script>
<style>
.roles .ant-table-thead > tr > th {
  font-weight: bold;
  color: #6b7280;
}
</style>
