<template>
  <Settings />
</template>
<script>
import Settings from "@/components/Dashboard/Alerts/Settings.vue";

export default {
  components: { Settings },
};
</script>
